
import Vue from 'vue'
import VueRouter from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { Message } from "element-ui";


NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})

Vue.use(VueRouter)

const routes = [
  //登陆
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  //index
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue'),
    children: [
      {
        //基础
        path: '/index/foundation',
        name: 'foundation',
        component: () => import('../components/Foundation.vue'),
        children: [
          {
            //首页
            path: '/index/foundation/home',
            name: 'home',
            component: () => import('../components/Foundation/Home.vue')
          },
          {
            //分类
            path: '/index/foundation/classify',
            name: 'classify',
            component: () => import('../components/Foundation/Classify.vue')
          },
          {
            //金刚区
            path: '/index/foundation/diamond',
            name: 'diamond',
            component: () => import('../components/Foundation/Diamond.vue')
          },
          {
            //banner
            path: '/index/foundation/banner',
            name: 'banner',
            component: () => import('../components/Foundation/Banner.vue')
          },


          {
            //邀请统计
            path: '/index/foundation/yaoqing',
            name: 'yaoqing',
            component: () => import('../components/Foundation/Yaoqing.vue')
          },

          {
            //合伙人统计
            path: '/index/foundation/hehuoren',
            name: 'hehuoren',
            component: () => import('../components/Foundation/Hehuoren.vue')
          },


          {
            //标题库
            path: '/index/foundation/labeldatabase',
            name: 'labeldatabase',
            component: () => import('../components/Foundation/LabelDatabase.vue')
          },
          {
            //会员
            path: '/index/foundation/member',
            name: 'member',
            component: () => import('../components/Foundation/Member.vue')
          },
          {
            //添加企业
            path: '/index/foundation/addenterprise',
            name: 'addenterprise',
            component: () => import('../components/Foundation/AddEnterprise.vue')
          },
          {
            //规则
            path: '/index/foundation/rules',
            name: 'rules',
            component: () => import('../components/Foundation/Rules.vue')
          },

          {
            //二级账户
            path: '/index/foundation/secondaryaccount',
            name: 'secondaryaccount',
            component: () => import('../components/Foundation/SecondaryAccount.vue')
          },
          //重定向
          {
            path: '/index/foundation',
            redirect: "/index/foundation/home"
          },
        ]
      },
      {
        //客服
        path: '/index/chat',
        name: 'chat',
        component: () => import('../components/Chat.vue'),
        children: [
          {
            //问答
            path: '/index/chat/customerservice',
            name: 'customerservice',
            component: () => import('../components/Chat/CustomerService.vue')
          },

        
          {
            //反馈
            path: '/index/chat/feedbacklist',
            name: 'feedbacklist',
            component: () => import('../components/Chat/FeedbackList.vue')
          },
           


          //重定向
          {
            path: '/index/chat',
            redirect: "/index/chat/customerservice"
          },
        ]
      },
      {
        //企业
        path: '/index/enterprise',
        name: 'enterprise',
        component: () => import('../components/Enterprise.vue'),
        children: [
          {
            //入驻企业
            path: '/index/enterprise/settledenterprise',
            name: 'settledenterprise',
            component: () => import('../components/Enterprise/SettledEnterprise.vue')
          },
          {
            //金刚区企业列表
            path: '/index/enterprise/jingangenterprise',
            name: 'jingangenterprise',
            component: () => import('../components/Enterprise/JingangEnterprise.vue')
          },
          {
            //项目列表
            path: '/index/enterprise/projectlist',
            name: 'projectlist',
            component: () => import('../components/Enterprise/ProjectList.vue')
          },
          {
            //审核通过
            path: '/index/enterprise/auditprojectlist',
            name: 'auditprojectlist',
            component: () => import('../components/Enterprise/Auditprojectlist.vue')
          },
          {
            //审核未通过
            path: '/index/enterprise/noauditprojectlist',
            name: 'noauditprojectlist',
            component: () => import('../components/Enterprise/NoAuditprojectlist.vue')
          },
          {
            //用户列表
            path: '/index/enterprise/userlist',
            name: 'userlist',
            component: () => import('../components/Enterprise/UserList.vue')
          },
          //服务列表
          {
            path: '/index/enterprise/fuwulist',
            name: 'fuwulist',
            component: () => import('../components/Enterprise/FuwuList.vue')
          },
          //合作机构列表
          {
            path: '/index/enterprise/commissionlist',
            name: 'commissionlist',
            component: () => import('../components/Enterprise/CommissionList.vue')
          },

           //banner待审核
           {
            path: '/index/enterprise/bannerdaishenhe',
            name: 'bannerdaishenhe',
            component: () => import('../components/Enterprise/Bannerdaishenhe.vue')
          },

           //banner已审核列表
           {
            path: '/index/enterprise/banneryishenhe',
            name: 'bannerdaishenhe',
            component: () => import('../components/Enterprise/Banneryishenhe.vue')
          },

           //banner已审核列表
           {
            path: '/index/enterprise/addvideos',
            name: 'addvideos',
            component: () => import('../components/Enterprise/Addvideos.vue')
          },

           //banner专访视频列表
           {
            path: '/index/enterprise/zhuanfvideos',
            name: 'zhuanfvideos',
            component: () => import('../components/Enterprise/Zhuanfvideos.vue')
          },





          //服务详情
          {
            path: '/fuwudetails',
            name: 'fuwudetails',
            component: () => import('../components/Enterprise/FuwuDetails.vue')
          },

          {
            //政企对接
            path: '/index/enterprise/dockingup',
            name: 'dockingup',
            component: () => import('../components/Enterprise/Dockingup.vue')
          },
          {
            //政企对接详情
            path: '/details',
            name: 'details',
            component: () => import('../components/Enterprise/Details.vue')
          },

          {
            //项目团队
            path: '/index/enterprise/koguo',
            name: 'koguo',
            component: () => import('../components/Enterprise/Koguo.vue')
          },
          {
            //详情
            path: '/koguodetails',
            name: 'koguodetails',
            component: () => import('../components/Enterprise/Koguodetails.vue')
          },
          //重定向
          {
            path: '/index/enterprise',
            redirect: "/index/enterprise/settledenterprise"
          },
        ]
      },
      {
        //财务
        path: '/index/finance',
        name: 'finance',
        component: () => import('../components/Finance.vue'),
        children: [
          {
            //商城交易记录
            path: '/index/finance/malltransactions',
            name: 'malltransactions',
            component: () => import('../components/Finance/MallTransactions.vue')
          },
          {
            //项目款交易
            path: '/index/finance/projecttransactions',
            name: 'projecttransactions',
            component: () => import('../components/Finance/ProjectTransactions.vue')
          },
          {
            // 会员升级列表
            path: '/index/finance/memberupgradetransaction',
            name: 'memberupgradetransaction',
            component: () => import('../components/Finance/MemberUpgradeTransaction.vue')
          },
          {
            //推广交易
            path: '/index/finance/promotetransactions',
            name: 'promotetransactions',
            component: () => import('../components/Finance/PromoteTransactions.vue')
          },
          {
            // 会员升级列表
            path: '/index/finance/promotetransactions',
            name: 'promotetransactions',
            component: () => import('../components/Finance/PromoteTransactions.vue')
          },
          {
            // 企世汇佣金
            path: '/index/finance/qshcommission',
            name: 'qshcommission',
            component: () => import('../components/Finance/QshCommission.vue')
          },
          {
            //项目款待审核
            path: '/index/finance/projecthospitalityreview',
            name: 'projecthospitalityreview',
            component: () => import('../components/Finance/ProjecthospitalityReview.vue')
          },
          {
            //项目款已审核
            path: '/index/finance/projectpaymentreview',
            name: 'projectpaymentreview',
            component: () => import('../components/Finance/ProjectpaymentReview.vue')
          },
          {
            //钱包提现待审核
            path: '/index/finance/walletaudit',
            name: 'walletaudit',
            component: () => import('../components/Finance/WalletAudit.vue')
          },
          {
            //钱包提现已审核
            path: '/index/finance/walletunaudited',
            name: 'walletunaudited',
            component: () => import('../components/Finance/WalletUnaudited.vue')
          },
          {
            //商品/服务待审核
            path: '/index/finance/goodsservicespaymentd',
            name: 'goodsservicespaymentd',
            component: () => import('../components/Finance/GoodsServicespaymentD.vue')
          },
          {
            //商品/服务已审核
            path: '/index/finance/goodsservicespaymenty',
            name: 'goodsservicespaymenty',
            component: () => import('../components/Finance/GoodsServicespaymentY.vue')
          },
          {
            //发票待审核
            path: '/index/finance/invoiced',
            name: 'invoiced',
            component: () => import('../components/Finance/InvoiceD.vue')
          },
          {
            //发票已审核
            path: '/index/finance/invoicey',
            name: 'invoicey',
            component: () => import('../components/Finance/InvoiceY.vue')
          },

          {
            //合伙人待审核
            path: '/index/finance/hhrdaishenhe',
            name: 'hhrdaishenhe',
            component: () => import('../components/Finance/Hhrdaishenhe.vue')
          },


          {
            //会员升级
            path: '/index/finance/qylist',
            name: 'qylist',
            component: () => import('../components/Finance/QyList.vue')
          },
          //重定向
          {
            path: '/index/finance',
            redirect: "/index/finance/malltransactions"
          },
        ]
      },
      {
        //系统
        path: '/index/system',
        name: 'system',
        component: () => import('../components/System.vue'),
        children: [
        
          {
            //节点管理
            path: '/index/system/node',
            name: 'node',
            component: () => import('../components/System/Node.vue')
          },
          {
            //节点管理
            path: '/index/system/notice',
            name: 'notice',
            component: () => import('../components/System/Notice.vue')
          },
          {
            //部门
            path: '/index/system/department',
            name: 'department',
            component: () => import('../components/System/Department.vue')
          },
          {
            //员工
            path: '/index/system/staff',
            name: 'staff',
            component: () => import('../components/System/Staff.vue')
          },
        
          {
            //日志管理
            path: '/index/system/logmanagement',
            name: 'logmanagement',
            component: () => import('../components/System/LogManagement.vue')
          },
          {
            //回收站
            path: '/index/system/recyclebin',
            name: 'recyclebin',
            component: () => import('../components/System/Recyclebin.vue')
          },
          //重定向
          {
            path: '/index/system',
            redirect: "/index/system/node"
          },
        ]
      },

      {
        //兑换
        path: '/index/duihuan',
        name: 'duihuan',
        component: () => import('../components/Duihuan.vue'),
        children: [


          {
            //兑换商品列表
            path: '/index/duihuan/duihuanprolist',
            name: 'duihuanprolist',
            component: () => import('../components/Duihuan/Duihuanprolist.vue')
          },
        
          {
            //上传兑换商品
            path: '/index/duihuan/productmanager',
            name: 'productmanager',
            component: () => import('../components/Duihuan/Productmanager.vue')
          },


          {
            //兑换商品修改
            path: '/index/duihuan/productxiugai',
            name: 'productxiugai',
            component: () => import('../components/Duihuan/Productxiugai.vue')
          },

     
          //重定向
          {
            path: '/index/duihuan',
            redirect: "/index/duihuan/Duihuanprolist"
          },
        ]
      },




      {
        //商城
        path: '/index/shoppingmall',
        name: 'shoppinmall',
        component: () => import('../components/ShoppingMall.vue'),
        children: [
          {
            //商品列表
            path: '/index/shoppingmall/goodslist',
            name: 'goodslist',
            component: () => import('../components/ShoppingMall/Goodslist.vue')
          },
          // {
          //   //商品管理
          //   path: '/index/shoppingmall/manage',
          //   name: 'manage',
          //   component: () => import('../components/ShoppingMall/Manage.vue')
          // },
          // {
          //   //商品属性
          //   path: '/index/shoppingmall/attribute',
          //   name: 'attribute',
          //   component: () => import('../components/ShoppingMall/Attribute.vue')
          // },
          {
            //优惠券
            path: '/index/shoppingmall/coupon',
            name: 'coupon',
            component: () => import('../components/ShoppingMall/Coupon.vue')
          },
          {
            //商品和服务类目
            path: '/index/shoppingmall/sfcategory',
            name: 'sfcategory',
            component: () => import('../components/ShoppingMall/SfCategory.vue')
          },
          // {
          //   //用户优惠券
          //   path: '/index/shoppingmall/usercoupon',
          //   name: 'usercoupon',
          //   component: () => import('../components/ShoppingMall/UserCoupon.vue')
          // },
          // {
          //   //秒杀管理
          //   path: '/index/shoppingmall/seckill',
          //   name: 'seckill',
          //   component: () => import('../components/ShoppingMall/Seckill.vue')
          // },
          // {
          //   //团购管理
          //   path: '/index/shoppingmall/groupbuying',
          //   name: 'groupbuying',
          //   component: () => import('../components/ShoppingMall/GroupBuying.vue')
          // },
          // {
          //   //商城订单
          //   path: '/index/shoppingmall/orderforgoods',
          //   name: 'orderforgoods',
          //   component: () => import('../components/ShoppingMall/OrderforGoods.vue')
          // },
          //重定向
          {
            path: '/index/shoppingmall',
            redirect: "/index/shoppingmall/goodslist"
          },
        ]
      },
      //重定向
      {
        path: '/index',
        redirect: "/index/foundation"
      },
    ]
  },

  //企业详情
  {
    path: '/enterprisedetails',
    name: 'enterprisedetails',
    component: () => import('../views/EnterpriseDetails.vue')
  },
  //企业装修
  {
    path: '/businessediting',
    name: 'businessediting',
    component: () => import('../components/Enterprise/BusinessEditing.vue')
  },
  //店铺
  {
    path: '/dianpuindex',
    name: 'dianpuindex',
    component: () => import('../components/Enterprise/Dianpuindex.vue'),
    children: [
      //企业信息
      {
        path: '/dianpuindex/companyinformation',
        name: 'companyinformation',
        component: () => import('../components/Enterprise/Dianpu/CompanyInformation.vue')
      },
      //业务详情
      {
        path: '/dianpuindex/businessdetails',
        name: 'businessdetails',
        component: () => import('../components/Enterprise/Dianpu/BusinessDetails.vue')
      },
      //案例展示
      {
        path: '/dianpuindex/casepresentation',
        name: 'casepresentation',
        component: () => import('../components/Enterprise/Dianpu/CasePresentation.vue')
      },
      //案例添加
      {
        path: '/dianpuindex/caseaddition',
        name: 'caseaddition',
        component: () => import('../components/Enterprise/Dianpu/CaseAddition.vue')
      },
      //案例详情
      {
        path: '/dianpuindex/casedetails',
        name: 'casedetails',
        component: () => import('../components/Enterprise/Dianpu/CaseDetails.vue')
      },
      //编辑案例详情
      {
        path: '/dianpuindex/modifyingcasedetails',
        name: 'modifyingcasedetails',
        component: () => import('../components/Enterprise/Dianpu/ModifyingCaseDetails.vue')
      },
      //订单管理
      {
        path: '/dianpuindex/indentmanage',
        name: 'indentmanage',
        component: () => import('../components/Enterprise/Dianpu/IndentManage.vue')
      },
      //服务添加标题
      // {
      //   path: '/dianpuindex/fuwutitle',
      //   name: 'fuwutitle',
      //   component: () => import('../components/Enterprise/Dianpu/FuwuTitle.vue')
      // },
      //服务列表
      {
        path: '/dianpuindex/fuwulist',
        name: 'fuwulist',
        component: () => import('../components/Enterprise/FuwuList.vue')
      },
      //服务详情
      {
        path: '/dianpuindex/fuwudetails',
        name: 'fuwudetails',
        component: () => import('../components/Enterprise/FuwuDetails.vue')
      },
      //重定向
      {
        path: '/dianpuindex',
        redirect: "/dianpuindex/companyinformation"
      },
    ]
  },
  //项目详情
  {
    path: '/detailspage',
    name: 'detailspage',
    component: () => import('../views/DetailsPage.vue')
  },
  //商品详情
  {
    path: '/gooddetails',
    name: 'gooddetails',
    component: () => import('../components/ShoppingMall/GoodDetails.vue')
  },
  //添加会员权限
  {
    path: '/addMember',
    name: 'addMember',
    component: () => import('../components/Foundation/AddMember.vue')
  },
  //重定向
  {
    path: '/',
    redirect: "/login"
  },
  //404
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


var router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token") || ""
  if (token) {

   
    NProgress.start();
    next();
  } else {
    if (to.path == "/login") {
      next()
    } else {
      Message({
        message: "您没有登录，请先登录",
        duration: 5000,
        type: "warning",
      });
      next({ path: "/login" })
    }
  }
})





router.afterEach(() => {
  NProgress.done()
})


export default router


